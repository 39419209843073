<template>
    <default-layout>
        <SpainhnNavigationBreadcrumb :navigation-links="links"/>
        <div class="container-fluid px-md-5 px-1">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard px-lg-5">
                    <div class="d-flex flex-column pt-3 col-12 px-md-5">
                        <div class="row py-5">
                            <div class="col-12 col-md-6 mx-auto">
                                <p class="text-center spainhn-subtitle-2-black">{{ $t( 'default.CONTACT_TITLE' ) }}</p>
                                <p class="spainhn-text text-center"> {{ $t('default.CONTACT_TEXT') }} </p>
                            </div>
                        </div> 

                        <div class="row">
                            <div class="col-md-6 col-12 mx-auto">
                                <b-form id="submit-contact" @submit="onSubmit">
                                    
                                    <b-form-group class="mb-2" label="Monumento de interés">
                                        <!-- <b-form-select :options="monumentos" ></b-form-select> -->
                                        <b-form-input type="text" name="monumento" v-model="monumento"></b-form-input>
                                    </b-form-group>

                                    <b-form-radio-group name="tipo"  v-model="tipoEntrada" label="Tipo de servicio" class="d-flex justify-content-around">
                                        <b-form-radio value="Visita">Visita</b-form-radio>
                                        <b-form-radio value="Experiencia">Experiencia</b-form-radio>
                                        <b-form-radio value="Evento">Evento</b-form-radio>
                                    </b-form-radio-group>

                                    <b-form-group class="mt-2" label="Nombre de entrada/experiencia/evento">
                                        <b-form-input name="visita" type="text" v-model="visita"></b-form-input >
                                    </b-form-group>
                                    <b-form-group label="Fecha de interés">
                                        <b-form-input name="fecha" type="date" v-model="fecha"></b-form-input>
                                    </b-form-group>
                                    <b-form-input v-model="nombre" name="nombre" :placeholder="$t('default.CONTACT_NAME_PLACEHOLDER')" class="my-2" type="text" required/>
                                    <b-form-input v-model="email" name="email" :placeholder="$t('default.CONTACT_EMAIL_PLACEHOLDER')" class="my-2" type="email" required/>
                                    <b-form-input  v-model="telefono" name="telefono" :placeholder="$t('default.CONTACT_PHONE_PLACEHOLDER')" class="my-2" type="tel" required/> 
                                    <b-form-textarea v-model="comentarios" name="comentarios" rows="5" :placeholder="$t('default.CONTACT_COMMENT_PLACEHOLDER')" class="my-2" type="text"/>
                                    <b-form-checkbox v-model="accept" class="my-3 text-center d-flex" required><small class="ms-2 me-2 my-auto">Acepto la <a class="link-dark">política de privacidad y tratamiento de datos</a> de Spain Heritage Tours</small></b-form-checkbox>
                                    <div class="w-100">
                                        <mdb-btn color="indigo" class="spainhn-button w-100 py-3 spainhn-text" style="font-size:16pt" block type="submit" :disabled="sending">{{ $t('default.SPAINHN_BUTTON_SEND') }}</mdb-btn>
                                    </div>
                                </b-form>
                            </div>
                        </div>
                        <success-alert @close="cerrarAlerta" :mensaje="mensaje" :show="showAlert"></success-alert>
                    </div>
                </div>
            </div>
        </div>
        <mdb-container>
            <ComponentsCloud />
        </mdb-container>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import SuccessAlert from '../components/SuccessAlert.vue'
import SpainhnNavigationBreadcrumb from '../components/SpainhnNavigationBreadcrumb.vue';
import ComponentsCloud from '../components/ComponentsCloud.vue';
export default {
    components:{
        DefaultLayout,
        SuccessAlert,
        SpainhnNavigationBreadcrumb,
        ComponentsCloud
    },
    data(){
        return{
            eventos:[],
            monumentos:[],

            tipoEntrada: 'Visita',
            monumento: null,
            visita: null,
            accept: null,
            comentarios: null,
            telefono: null,
            nombre: null,
            email: null,
            fecha: null,
            mensaje: "ok",
            showAlert: false,
            sending: false,
            links:[
                { text: 'INICIO', navigation: true, navigationLink: '/' },
                { text: 'VOLVER', navigation: true, navigationLink: '' },
            ]
        }
    },
    methods:{
        cerrarAlerta(){
            this.showAlert = false;
        },
        goToHome(){
            let $vm = this;
            $vm.$router.push('/')
        },
        onSubmit(event){
            let $vm = this;
            $vm.showAlert = false;
            event.preventDefault();
            // Creamos el objeto que contiene la información
            let form = {
                tipoEntrada: $vm.tipoEntrada,
                monumento: $vm.monumento,
                visita: $vm.visita,
                comentarios: $vm.comentarios,
                telefono: $vm.telefono,
                nombre: $vm.nombre,
                email: $vm.email,
                fecha: $vm.fecha
            }
            if(this.accept){
                $vm.sending = true;
                ApiService.sendInfoEmail(form)
                    .then( res => {
                        this.sending = false;
                        console.log(res);
                        $vm.mensaje = "Tu mensaje se envío correctamente!";
                        $vm.showAlert = true;
                    })
                    .catch(err => {
                        this.sending = false;
                        $vm.mensaje = "Error al enviar el mensaje"
                        console.log(err);
                        $vm.showAlert = true;
                    })
            }

        }
    },
    setup() {
        
    },
    created(){
        let $vm = this;
        // Cargamos los parámetros recibidos
        let params = this.$route.query;
        if(params.monumento){
            $vm.monumento = params.monumento;
        }
        if( params.tipo ){
            $vm.tipoEntrada = params.tipo;
        }
        else{
            params.tipoEntrada = null;
        }

        if( params.visita ){
            $vm.visita =  params.visita;
        }
        else{
            $vm.visita = null;
        }
    },
    computed: {
        
    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

        @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}
</style>