var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("SpainhnNavigationBreadcrumb", {
        attrs: { "navigation-links": _vm.links },
      }),
      _c("div", { staticClass: "container-fluid px-md-5 px-1" }, [
        _c("div", { staticClass: "container-fluid mb-5" }, [
          _c(
            "div",
            { staticClass: "row text-start margen-estandard px-lg-5" },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column pt-3 col-12 px-md-5" },
                [
                  _c("div", { staticClass: "row py-5" }, [
                    _c("div", { staticClass: "col-12 col-md-6 mx-auto" }, [
                      _c(
                        "p",
                        { staticClass: "text-center spainhn-subtitle-2-black" },
                        [_vm._v(_vm._s(_vm.$t("default.CONTACT_TITLE")))]
                      ),
                      _c("p", { staticClass: "spainhn-text text-center" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("default.CONTACT_TEXT")) + " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12 mx-auto" },
                      [
                        _c(
                          "b-form",
                          {
                            attrs: { id: "submit-contact" },
                            on: { submit: _vm.onSubmit },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-2",
                                attrs: { label: "Monumento de interés" },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", name: "monumento" },
                                  model: {
                                    value: _vm.monumento,
                                    callback: function ($$v) {
                                      _vm.monumento = $$v
                                    },
                                    expression: "monumento",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-radio-group",
                              {
                                staticClass: "d-flex justify-content-around",
                                attrs: {
                                  name: "tipo",
                                  label: "Tipo de servicio",
                                },
                                model: {
                                  value: _vm.tipoEntrada,
                                  callback: function ($$v) {
                                    _vm.tipoEntrada = $$v
                                  },
                                  expression: "tipoEntrada",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  { attrs: { value: "Visita" } },
                                  [_vm._v("Visita")]
                                ),
                                _c(
                                  "b-form-radio",
                                  { attrs: { value: "Experiencia" } },
                                  [_vm._v("Experiencia")]
                                ),
                                _c(
                                  "b-form-radio",
                                  { attrs: { value: "Evento" } },
                                  [_vm._v("Evento")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  label: "Nombre de entrada/experiencia/evento",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { name: "visita", type: "text" },
                                  model: {
                                    value: _vm.visita,
                                    callback: function ($$v) {
                                      _vm.visita = $$v
                                    },
                                    expression: "visita",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Fecha de interés" } },
                              [
                                _c("b-form-input", {
                                  attrs: { name: "fecha", type: "date" },
                                  model: {
                                    value: _vm.fecha,
                                    callback: function ($$v) {
                                      _vm.fecha = $$v
                                    },
                                    expression: "fecha",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              staticClass: "my-2",
                              attrs: {
                                name: "nombre",
                                placeholder: _vm.$t(
                                  "default.CONTACT_NAME_PLACEHOLDER"
                                ),
                                type: "text",
                                required: "",
                              },
                              model: {
                                value: _vm.nombre,
                                callback: function ($$v) {
                                  _vm.nombre = $$v
                                },
                                expression: "nombre",
                              },
                            }),
                            _c("b-form-input", {
                              staticClass: "my-2",
                              attrs: {
                                name: "email",
                                placeholder: _vm.$t(
                                  "default.CONTACT_EMAIL_PLACEHOLDER"
                                ),
                                type: "email",
                                required: "",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _c("b-form-input", {
                              staticClass: "my-2",
                              attrs: {
                                name: "telefono",
                                placeholder: _vm.$t(
                                  "default.CONTACT_PHONE_PLACEHOLDER"
                                ),
                                type: "tel",
                                required: "",
                              },
                              model: {
                                value: _vm.telefono,
                                callback: function ($$v) {
                                  _vm.telefono = $$v
                                },
                                expression: "telefono",
                              },
                            }),
                            _c("b-form-textarea", {
                              staticClass: "my-2",
                              attrs: {
                                name: "comentarios",
                                rows: "5",
                                placeholder: _vm.$t(
                                  "default.CONTACT_COMMENT_PLACEHOLDER"
                                ),
                                type: "text",
                              },
                              model: {
                                value: _vm.comentarios,
                                callback: function ($$v) {
                                  _vm.comentarios = $$v
                                },
                                expression: "comentarios",
                              },
                            }),
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "my-3 text-center d-flex",
                                attrs: { required: "" },
                                model: {
                                  value: _vm.accept,
                                  callback: function ($$v) {
                                    _vm.accept = $$v
                                  },
                                  expression: "accept",
                                },
                              },
                              [
                                _c(
                                  "small",
                                  { staticClass: "ms-2 me-2 my-auto" },
                                  [
                                    _vm._v("Acepto la "),
                                    _c("a", { staticClass: "link-dark" }, [
                                      _vm._v(
                                        "política de privacidad y tratamiento de datos"
                                      ),
                                    ]),
                                    _vm._v(" de Spain Heritage Tours"),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "w-100" },
                              [
                                _c(
                                  "mdb-btn",
                                  {
                                    staticClass:
                                      "spainhn-button w-100 py-3 spainhn-text",
                                    staticStyle: { "font-size": "16pt" },
                                    attrs: {
                                      color: "indigo",
                                      block: "",
                                      type: "submit",
                                      disabled: _vm.sending,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("default.SPAINHN_BUTTON_SEND")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("success-alert", {
                    attrs: { mensaje: _vm.mensaje, show: _vm.showAlert },
                    on: { close: _vm.cerrarAlerta },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _c("mdb-container", [_c("ComponentsCloud")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }